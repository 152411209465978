// Banner
.mainBanner {
  width: 100%;
  position: relative;
  margin-top: -5%;
  img {
    width: 100%;
    @include media-query(767px) {
      height: 80vh;
      object-fit: cover;
    }
  }
}
.mainImg{
  position: absolute;
  right: 0;
  top: 20%;
  z-index: 0;
  @include media-query(767px) {
    display: none;
  }
}
.img-right1{
  z-index: 9;
  position: relative;
}
.customDivider{
  background: rgba(174, 228, 237, 0.10);
}
.hacsCard {
  padding: 32px;
  padding: calc-rem-value(32);
  position: absolute;
  bottom: 45%;
  left: 5%;
  width: 55%;
  margin-bottom: 1%;
  border-left: 8px solid rgba(174, 228, 237, 0.10) ;
  display: block;;
  @include media-query(767px) {
    transform: scale(0.85);
    width: 100%;
    bottom: 20%;
    left: 0;
    padding: 16px;
    display: none;
  }
  h3 {
    color: rgba(78, 145, 221, 0.60);
    font-family: $heading-font;
    font-size: 53.648px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 5.365px;
  }
}
.bannerCard {
  border: 1px solid rgba(139, 238, 255, 0.86);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.1) -12.66%,
    rgba(60, 51, 154, 0.1) 97.77%
  );
  box-shadow: 10px 12px 25px -12px rgba(174, 228, 237, 0.32) inset;
  backdrop-filter: blur(6px);
  padding: 32px;
  padding: calc-rem-value(32);
  position: absolute;
  bottom: 15%;
  left: 5%;
  width: 75%;
  @include media-query(767px) {
    transform: scale(0.85);
    width: 100%;
    bottom: 10%;
    left: 0;
    padding: 16px;
  }
  h1 {
    color: $hacs-white;
    font-family: $heading-font;
    font-size: 28px;
    font-size: calc-rem-value(28);
    line-height: 43px;
    line-height: cal-rem-value(43);
    font-weight: 400;
    margin-bottom: 20px;
    margin-bottom: calc-rem-value(20);
    @include media-query(767px) {
      font-size: 20px;
      font-size: calc-rem-value(20);
      line-height: 35px;
      line-height: cal-rem-value(35);
    }
  }
  h3{
    color: rgba(174, 228, 237, 1);
    font-family: $heading-font;
    font-size:30px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 5.365px;
    display: none;
    @include media-query(767px) {
      display: block;
      line-height: 45px;
    }
  }
  h6 {
    color: $hacs-white;
    font-family: $base-font;
    font-size: 18px;
    font-size: calc-rem-value(18);
    line-height: 43px;
    line-height: calc-rem-value(28);
    font-weight: 200;
    margin-bottom: 20px;
    margin-bottom: calc-rem-value(20);
  }
}

// Banner

// IntroSection

.introSvgWrapper {
  background-image: url("../../Pages/Home/components/IntroSection/img/section-1.svg");
  height: 280px;
  background-size: cover;
  @include media-query(767px) {
    height: 100px;
  }
}
.introSection {
  h1 {
    color: $hacs-blue;
    font-family: $heading-font;
    font-size: 40px;
    font-size: calc-rem-value(40);
    line-height: 1.25;
    margin-bottom: 28px;
    @include media-query(767px) {
      font-size: 28px;
      font-size: calc-rem-value(28);
    }
  }
  h2 {
    color: $hacs-blue;
    font-family: $heading-font;
    font-size: 28px;
    font-size: calc-rem-value(28);
    line-height: 43px;
    margin-bottom: 28px;
    @include media-query(767px) {
      font-size: 16px;
      font-size: calc-rem-value(16);
      line-height: 24px;
      margin-bottom: 14px;
    }
  }
  h5 {
    color: $hacs-black;
    font-family: $base-font;
    font-size: 15px;
    font-size: calc-rem-value(15);
    line-height: 28px;
    font-weight: 300;
    text-align: left;
  }
  h6 {
    color: $hacs-black;
    font-family: $base-font;
    font-size: 18px;
    font-size: calc-rem-value(18);
    line-height: 43px;
    font-weight: 300;
    @include media-query(767px) {
      font-size: 14px;
      font-size: calc-rem-value(14);
      line-height: 24px;
    }
  }
  .bgLeft {
    position: absolute;
    left: 0;
    margin-top: -5%;
    @include media-query(767px) {
      margin-top: unset;
      transform: scale(0.75);
    }
  }
  .bgLast {
    position: absolute;
    right: 0;
    margin-top: -15%;
  }
  .bgRight {
    position: absolute;
    right: 0;
    margin-top: 15%;
    @include media-query(767px) {
      opacity: 0.15;
    }
  }
  .customDivider {
    width: 5px !important;
    opacity: 0.1;
    background: $hacs-blue;
  }
  .customDivider2 {
    width: 5px !important;
    opacity: 0.1;
    background: $hacs-white;
  }
  .outlined-black {
    border-color: $hacs-blue !important;
    color: $hacs-black !important;
    text-transform: uppercase;
    margin-top: 30px;
    margin-top: calc-rem-value(30);
    font-family: $base-font !important;
    font-weight: 600 !important;
    padding: 3% 10% !important;
  }
}
.introMiddleSection {
  background:#4E91DD40;
  width: 85%;
  padding: 4% 8%;
  position: relative;
  z-index: inherit;
  @include media-query(767px) {
    width: 100%;
    padding: 4% 2%;
    position: relative;
  }
  svg {
    position: absolute;
    left: 0;
    top: 10%;
    transform: scale(1.75);
    overflow: hidden;
  }
  h6 {
    font-size: 24px;
    font-size: calc-rem-value(24);
    color: $hacs-black;
    font-weight: 300;
    font-family: $base-font !important;
    @include media-query(767px) {
      font-size: 18px;
      font-size: calc-rem-value(18);
      color: $hacs-black;
      font-weight: 300;
      font-family: $base-font !important;
    }
  }
}
// IntroSection

// Gradient Section
.gradientSection {
  background: linear-gradient(180deg, #FFF 0%, #4E91DD 58.75%, #073857 109.53%);
  padding: 80px 0 180px 0;
  @include media-query(767px) {
    padding: 40px 0 40px 0;
  }

  h1 {
    color: $hacs-blue;
    font-family: $heading-font;
    font-size: 30px;
    font-size: calc-rem-value(30);
    line-height: 50px;
    line-height: calc-rem-value(50);
    margin-bottom: 2rem;
    @include media-query(767px) {
      font-size: 20px;
      font-size: calc-rem-value(20);
      line-height: 25px;
      line-height: calc-rem-value(25);
      margin-bottom: 1rem;
    }
  }
  h6 {
    font-size: 14px;
    font-size: calc-rem-value(14);
    color: $hacs-black;
    font-family: $base-font;
    font-weight: 300;
    margin-bottom: 0.35rem;
  }
  h4 {
    font-size: 16px;
    font-size: calc-rem-value(16);
    color: $hacs-white;
    font-family: $base-font;
    font-weight: 300;
    margin-bottom: 0.35rem;
  }
  h5 {
    font-size: 16px;
    font-size: calc-rem-value(16);
    font-family: $base-font;
    line-height: 28px;
    color: $hacs-black;
    opacity: 0.8;
  }
  h2 {
    color: $hacs-blue;
    font-family: $heading-font;
    font-size: 28px;
    font-size: calc-rem-value(28);
    line-height: 50px;
    line-height: calc-rem-value(50);
    margin-bottom: 2rem;
    @include media-query(767px) {
      font-size: 20px;
      font-size: calc-rem-value(20);
      line-height: 25px;
      line-height: calc-rem-value(25);
      margin-bottom: 1rem;
    }
  }
  .carouselCard {
    border: 2px solid #fff;
    background: linear-gradient(
      108deg,
      rgba(255, 255, 255, 0.8) -1.72%,
      rgba(255, 255, 255, 0) 84.1%
    );
    backdrop-filter: blur(5px);
    margin: 0 40px;
    .inner {
      padding: 15px;
    }
    .bgWhite {
      background-color: $hacs-white;
      height: 100%;
      padding: 40px 20px 40px 0;
      text-align: center;
      border: 2px solid #fff;
    }
    h5 {
      font-size: 18px;
      font-size: calc-rem-value(18);
      color: $hacs-black;
      font-family: $base-font;
      margin-bottom: 1.25rem;
    }

    .outlined-black {
      border-color: $hacs-blue !important;
      color: $hacs-black !important;
      text-transform: uppercase;
      margin-top: calc-rem-value(30);
      font-family: $base-font !important;
      font-weight: 600 !important;
      padding: 3% 10% !important;
    }
  }
  .gradientSvgWrapper {
    background-image: url("../../Pages/Home/components/GradientSection/img/gradientFooter.svg");
    height: 280px;
    background-size: cover;
    @include media-query(767px) {
      height: 100px;
    }
  }
}
.colorWhite{
  color: $hacs-white !important;
}
.colorWhite80{
  color: $hacs-white !important;
  opacity: 0.8;
}
// Bottom Section

.bottomSection {
  background: url("../../Pages/Home/components/BottomSection/img/world-map.svg");
  background-size: 75%;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  padding: 7% 0 0 0;
  @include media-query(767px) {
    background-size: 95%;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    padding: 2% 0 0 0;
  }
  h1 {
    color: $hacs-blue;
    font-size: 40px !important;
    font-size: calc-rem-value(40);
    font-family: $heading-font !important;
    font-weight: 400;
    text-transform: capitalize;
    text-align: center;
    @include media-query(767px) {
      font-size: 20px !important;
      font-size: calc-rem-value(20);
      font-family: $heading-font !important;
      font-weight: 400;
      text-transform: capitalize;
      text-align: center;
    }
  }
  h3 {
    color: $hacs-blue;
    font-size: 20px !important;
    font-size: calc-rem-value(20);
    font-family: $base-font;
    font-weight: 600;
    text-transform: capitalize;
  }
  h5 {
    color: $hacs-black;
    font-size: 12px !important;
    font-size: calc-rem-value(20);
    font-family: $base-font;
    font-weight: 400;
    line-height: 1.75;
  }

  .imgCard {
    clip-path: polygon(59% 1%, 100% 38%, 100% 100%, 0 100%, 0 0);
    background-image: url("../../Pages/Home/components/BottomSection/img/people.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left left;
    padding: 40px;
    position: relative;
    display: inline-block;
    height: 400px;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 10%;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: linear-gradient(
        180deg,
        rgba(60, 51, 154, 0) 4.05%,
        #3c339a 100%
      );
    }
    .inner {
      padding-top: 30%;
      @include media-query(767px) {
        padding-top: 10%;
      }
    }
    h2 {
      color: $hacs-white;
      font-size: 30px;
      font-size: calc-rem-value(30);
      font-family: $base-font;
      font-weight: 600;
      text-transform: capitalize;
      position: relative;
      z-index: 9;
      margin-bottom: 0.75rem;
    }
    h6 {
      color: $hacs-white;
      font-size: 14px;
      font-size: calc-rem-value(14);
      font-family: $base-font;
      font-weight: 300;
      position: relative;
      z-index: 9;
      margin-bottom: 0.75rem;
    }
    a {
      color: $hacs-white;
      font-size: 12px;
      font-size: calc-rem-value(12);
      font-family: $base-font;
      font-weight: 400;
      position: relative;
      z-index: 9;
      cursor: pointer;
      text-transform: capitalize;
    }
  }
  .blueBox {
    clip-path: polygon(59% 1%, 100% 38%, 100% 100%, 0 100%, 0 0);
    background-color: #3c339a;
    padding: 40px;
    height: 400px;
  }
  .inner {
    padding-top: 60%;
    @include media-query(767px) {
      padding-top: 30%;
    }
  }
  h2 {
    color: $hacs-white;
    font-size: 20px;
    font-size: calc-rem-value(20);
    font-family: $base-font;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    z-index: 9;
    margin-bottom: 0.75rem;
  }
  h6 {
    color: $hacs-white;
    font-size: 12px;
    font-size: calc-rem-value(12);
    font-family: $base-font;
    font-weight: 300;
    position: relative;
    z-index: 9;
    margin-bottom: 0.75rem;
  }
  a {
    color: $hacs-white;
    font-size: 12px;
    font-size: calc-rem-value(12);
    font-family: $base-font;
    font-weight: 400;
    position: relative;
    z-index: 9;
    cursor: pointer;
    text-transform: capitalize;
  }
  .introSvgWrapper {
    position: absolute;
    margin-top: -20%;
    z-index: -1;
  }
  .leftShadow {
    position: absolute;
    left: 0;
    @include media-query(767px) {
      display: none;
    }
  }
  .rightShadow {
    position: absolute;
    right: 0;
    top: 15%;
    opacity: 0.5;
    height: 600px;
  }
  .mapSection {
    border: 1px solid #FFF;
    opacity: 0.4;
    background: linear-gradient(272deg, rgba(78, 145, 221, 0.12) -11.19%, rgba(78, 145, 221, 0.60) 111.28%);
    backdrop-filter: blur(6px);
    padding: 35px;
    .content {
      position: relative;
      opacity: 1 !important;
    }
    h6 {
      color: $hacs-black;
      font-family: $base-font;
      font-size: 20px;
      line-height: 35px;
      font-weight: 300;
      white-space: normal;
      span{
        color: #A13764;
      }
    }
  }
  .ceoSection {
    margin: 40px 0;
    ul li {
      font-size: 16px;
      font-family: $base-font;
      font-weight: 400;
      margin-top: 14px;
      list-style-type: square;
      color: $hacs-black;
      opacity:0.8 !important;
      &::marker {
      opacity: 0.2;
        color: #A13764;
        width: 20px;
        height: 20px;
      }
    }
    h2 {
      color: $hacs-blue;
      font-size: 28px;
      font-family: $heading-font;
    }
  }
}

// Testimonials Section

.testimonialsWrapper {
  margin: 8rem 0 0 0;
  background-color: $hacs-blue;
  // padding: 5rem 1rem;
  position: relative;
  overflow: hidden;
  @include media-query(767px) {
    // padding: 2rem 1rem;
    margin: 1rem 0 0 0;
  }
  .leftBox {
    position: absolute;
    left: 0;
    top: 0;
  }
  .rightBox {
    position: absolute;
    right: 10%;
    top: 0;
  }
  .testimonialsCard {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: linear-gradient(
      107deg,
      rgba(89, 75, 228, 0.46) -21.41%,
      rgba(93, 82, 210, 0.16) 102.9%
    );
    backdrop-filter: blur(10px);
    padding: 40px;
    padding: calc-rem-value(40);
    margin-right: 30px;
    h5 {
      color: $hacs-white;
      font-family: $base-font;
      font-size: 14px;
      font-size: calc-rem-value(14);
      line-height: 24px;
      font-weight: 400;
    }
    h4 {
      color: $hacs-white;
      font-family: $base-font;
      font-size: 14px;
      font-size: calc-rem-value(14);
      line-height: 24px;
      font-weight: 500;
      text-transform: capitalize;
    }
    h6 {
      color: $hacs-white;
      font-family: $base-font;
      font-size: 12px;
      font-size: calc-rem-value(12);
      line-height: 24px;
      font-weight: 300;
      text-transform: capitalize;
    }
  }
  h1 {
    color: $hacs-white;
    font-family: $heading-font;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    a {
      color: $hacs-white;
      cursor: pointer !important;
      z-index: 999;
      position: relative;
    }
    @include media-query(767px) {
      font-size: 18px;
    }
  }
}
.boxBottom {
  background-color: $hacs-blue;
  color: $hacs-white;
  font-family: $base-font;
  font-size: 24px;
  font-weight: 300;
  line-height: 33px;
  width: 80%;
  padding: 4% 5%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: -6%;
  position: relative;
  z-index: 999;
  @include media-query(767px) {
    padding: 4% 2%;
    margin-top: -14%;
  }
  h6 {
    color: $hacs-white;
    font-size: 24px;
    font-family: $base-font;
    font-weight: 300;
    line-height: 33px;
    @include media-query(767px) {
      font-size: 16px;
      font-family: $heading-font;
      text-align: center;
      font-weight: 400;
    }
  }
  .bottomBoxSvg {
    position: absolute;
    margin-top: -12%;
    left: -10%;
    z-index: -1;
    @include media-query(767px) {
      display: none;
    }
  }
}
