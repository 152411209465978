// Fonts
@import url("https://fonts.googleapis.com/css2?family=Michroma&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap");
// 

$base-font: 'Prompt', sans-serif;
$heading-font: 'Michroma', sans-serif;
$base-font-size: 14;

// Color
$hacs-blue: #073857;
$hacs-black: #484848;
$hacs-white: #FFFFFF

// Media Queries
