@mixin widther( $length ) {
    @media screen and (min-width: $length)  {
        @content;
    }
}

@mixin media-query( $length ) {
    @media (max-width: $length) {
        @content;
    }
}