.MuiPaper-root-MuiDrawer-paper{
    background-color: $hacs-blue !important;
}

.base-section{
  .MuiContainer-root {
    padding: 0 120px !important;
    @include media-query(767px) {
      padding: 0 14px !important;
    }
  }
}
// .MuiCardHeader-root{
//   background-color: #EFF9FB !important;
// }