.loginWrapper {
  background-image: url("../../Pages/Login/img/login-bg.webp");
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  overflow-y: hidden;
  .hacsloginCard {
    padding: 32px;
    padding: calc-rem-value(32);
    border-left: 8px solid rgba(174, 228, 237, 0.1);
    display: flex;
    align-items: center;
    h3 {
      color: rgba(174, 228, 237, 0.4);
      font-family: $heading-font;
      font-size: 53.648px;
      font-style: normal;
      font-weight: 400;
      line-height: 60px;
      letter-spacing: 5.365px;
    }
  }
  .loginLogo {
    position: absolute;
    left: 5%;
    top: 5%;
  }
  .MuiLoadingButton-root {
    background-color: $hacs-blue !important;
    box-shadow: 0px 17px 53px -13px rgba(108, 98, 212, 0.25) !important;
    width: 100% !important;
    padding: 15px !important;
    &:hover {
      background-color: $hacs-blue !important;
    }
  }
}

.loginCard {
  //   border: 1px solid rgba(139, 238, 255, 0.86);
  // background: linear-gradient(
  //   270deg,
  //   rgba(255, 255, 255, 0.1) -12.66%,
  //   rgba(60, 51, 154, 0.1) 97.77%
  // );
  box-shadow: 10px 12px 25px -12px rgba(174, 228, 237, 0.32) inset;
  background-color: #fff;
  // backdrop-filter: blur(30px);
  padding: 32px;
  padding: calc-rem-value(32);
  height: 100vh;
  display: flex;
  align-items: center;
  h1 {
    font-family: $heading-font;
    text-transform: uppercase;
    // color: rgba(174, 228, 237, 0.4);
    color: $hacs-blue;
    text-align: center;
    padding-bottom: 40px;
  }
}
