.footer {
  .footerSvgWrapper {
    background-image: url("../../Layouts/BaseLayout/Footer/img/footerSec.svg");
    height: 250px;
    background-size: cover;
    position: absolute;
    display: flex;
    align-items: center;
    @include media-query(767px) {
      height: 120px;
    }
  }
  .footerContainer {
    padding-top: 2.5% !important;
    padding-bottom: 2% !important;
    @include media-query(767px) {
      padding-top: 4.5% !important;
      padding-bottom: 2% !important;
    }
  }
  .footerLinks {
    color: $hacs-black;
    opacity: 0.8;
    font-family: $base-font;
    font-weight: 300;
    font-size: 16px !important;
    font-size: calc-rem-value(16) !important;
    text-transform: capitalize;
    cursor: pointer;
    .hacs {
      opacity: 0.8;
      color: #5fb7cf;
    }
  }
  .socialIcons {
    width: 48px;
    height: 48px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 9;
    cursor: pointer;
    &:hover {
      opacity: 0.85;
    }
  }
}
/* Example CSS for styling the back-to-top button */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: $hacs-blue;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  display: none;
  width: 48px;
  text-align: center;
  height: 48px;
  z-index: 9999;
}

.back-to-top.visible {
  display: block;
}
.copyright {
  font-family: $base-font;
  font-weight: 400;
  padding-top: 25px;
  letter-spacing: 1px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  cursor: pointer;
  z-index: 99;
  position: relative;
  span {
    font-weight: 700;
    color: $hacs-blue;
  }
  &:hover {
    letter-spacing: 2px;
  }
}
.contactSection {
  position: relative;
  z-index: 99;
  span {
    display: flex;
    font-family: $base-font;
    line-height: 2;
    justify-content: center;
    letter-spacing: 1px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    cursor: pointer;
    &:hover {
      letter-spacing: 2px;
    }
    @include media-query(767px) {
      justify-content: start;
    }
    svg {
      color: $hacs-blue;
    }
  }
}
