.main-header {
    padding: 15px 80px !important;
    background: linear-gradient(180deg, rgba(53, 46, 48, 0.60) -125.34%, rgba(53, 46, 48, 0.12) 116.05%) !important;
    backdrop-filter: blur(6px) !important;
    box-shadow: unset !important;
    @include media-query(767px) {
        padding: 8px 30px !important;
    }
}
.navItem {
    color: $hacs-white !important;
    font-family: $base-font !important;
    font-weight: 300 !important;
}
.outlined-white{
    border-color: $hacs-white !important;
    color: $hacs-white !important;
    font-family: $base-font !important;
    font: 600 !important;
}
