// helpers

@import "helpers/functions";
@import "helpers/variables";
@import "helpers/mixins";

// Components

@import "components/header";
@import "components/footer";
@import "components/muiOverrides";

// Pages

@import "pages/home";
@import "pages/login";


// Common

body {
  overflow-x: hidden !important;
  // background-color: $hacs-white !important;
  @include media-query(767px) {
    overflow-x: hidden !important;
  }
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.j-center {
  display: flex !important;
  justify-content: flex-start !important;
  @include media-query(767px) {
    display: flex !important;
    justify-content: center !important;
  }
}
